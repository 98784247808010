import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Link from 'gatsby-link'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { TitleWithLine, ButtonLink } from '../elements'

import pocketMoneyDetermineBG from '../../images/parent/chapter/pocket_money_determine_bg.svg'

const PaybackWrapper = styled.div`
  position: relative;
  padding-top: 70px;
  padding-bottom: 30px;
  &:before {
    content: '';
    position: absolute;
    top: 400px;
    left: 0;
    right: 0;
    bottom: 80px;
    background-image: url(${pocketMoneyDetermineBG});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: 1199px) {
      content: none;
    }
  }
`

const PaybackContainer = styled(Container)``

const PaybackTitleWrapper = styled(Col)`
  text-align: center;
  margin-bottom: 110px;
  @media (max-width: 767px) {
    margin-bottom: 50px;
  }
  @media (max-width: 575px) {
    margin-bottom: 10px;
  }
`

const PaybackTitle = styled(TitleWithLine)`
  @media (max-width: 575px) {
    font-size: 34px;
  }
  @media (max-width: 340px) {
    font-size: 28px;
  }
  span {
    &:after {
      left: 30%;
      right: 30%;
      @media (max-width: 767px) {
        transform: translateX(50%);
      }
    }
  }
`

const PaybackRowWrapper = styled(Row)`
  justify-content: center;
  padding-bottom: 50px;
  @media (max-width: 575px) {
    padding-bottom: 30px;
  }
`

const PaybackItem = styled(Col)`
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  position: relative;
  margin-bottom: 25px;
  outline: none;
  &:hover {
    &:after {
      background-color: rgba(1, 150, 98, 0.3);
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
    background-color: transparent;
    transition: all 0.2s ease;
    pointer-events: none;
  }
`

const PaybackItemFirst = styled(PaybackItem)`
  color: ${(props) => props.theme.colorGreenLight};
  line-height: 2.2em;
  padding-top: 20px;
  @media (min-width: 576px) {
    padding-right: 8%;
  }
  &:after {
    content: none;
  }
  p {
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 0;

    @media (min-width: 1200px) and (max-width: 1399px) {
      font-size: 24px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 23px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 18px;
    }
    @media (max-width: 575px) {
      font-size: 20px;
    }

    strong {
      font-size: 40px;
      font-weight: bold;
      line-height: 0;
      margin-bottom: 0;

      @media (min-width: 1200px) and (max-width: 1399px) {
        font-size: 22px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 21px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        font-size: 18px;
      }
      @media (max-width: 575px) {
        font-size: 20px;
      }
    }
  }
`

const PaybackItemLinkWrapper = styled(Link)``

const PaybackItemImageWrapper = styled.div``

const PaybackItemDescWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 35px;
  right: 35px;
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  line-height: 1.2;
  padding-top: 20px;
  padding-right: 45%;
  .name {
    position: relative;
    color: ${(props) => props.theme.colorText};
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 8px;
    @media (min-width: 1200px) and (max-width: 1399px) {
      font-size: 21px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 20px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 17px;
    }
    @media (max-width: 575px) {
      font-size: 22px;
    }
    &:after {
      content: '';
      position: absolute;
      left: -20px;
      right: 0;
      bottom: -6px;
      display: block;
      height: 1px;
      background-color: ${(props) => props.theme.colorWhite};
    }
  }
  .desc {
    color: ${(props) => props.theme.colorGreenLight};
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0;
    @media (min-width: 1200px) and (max-width: 1399px) {
      font-size: 23px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 22px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 18px;
    }
    @media (max-width: 575px) {
      font-size: 22px;
    }
  }
`

const PaybackItemLink = styled(ButtonLink)`
  position: absolute;
  right: 15px;
  bottom: 0;

  @media (max-width: 767px) {
    position: relative;
    right: auto;
    width: 100%;
  }
`

const Payback = (props) => {
  const data = useStaticQuery(graphql`
    query {
      expertImage1: file(relativePath: { eq: "parent/payback/expert1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 425, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertImage2: file(relativePath: { eq: "parent/payback/expert2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 425, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertImage3: file(relativePath: { eq: "parent/payback/expert3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 425, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertImage4: file(relativePath: { eq: "parent/payback/expert4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 425, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertImage5: file(relativePath: { eq: "parent/payback/expert5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 425, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const experts = [
    {
      name: `Monika Sajkowska`,
      text: (
        <>
          Kieszonkowe to nie tylko pieniądze w portfelu, czy na koncie dziecka…
        </>
      ),
      link:
        '/strefa-rodzica/czy-dawanie-kieszonkowego-sie-oplaca/monika-sajkowska',
    },
    {
      name: `Tatiana Mindewicz Puacz`,
      text: (
        <>Mogłoby się wydawać, że dzieci i pieniądze to dwa odległe światy…</>
      ),
      link:
        '/strefa-rodzica/czy-dawanie-kieszonkowego-sie-oplaca/tatiana-mindewicz-puacz',
    },
    {
      name: `Maciej Samcik`,
      text: (
        <>
          Oczywiście, że się opłaca, dawanie kieszonkowego to najtańsza lekcja
          zarządzania pieniędzmi…
        </>
      ),
      link:
        '/strefa-rodzica/czy-dawanie-kieszonkowego-sie-oplaca/maciej-samcik',
    },
    {
      name: `Natalia Tur`,
      text: (
        <>
          Moim zdaniem zdecydowanie tak. Każde z trójki moich dzieci otrzymuje
          kieszonkowe…
        </>
      ),
      link: '/strefa-rodzica/czy-dawanie-kieszonkowego-sie-oplaca/natalia-tur',
    },
    {
      name: `Aleksander Naganowski`,
      text: <>Na kieszonkowe warto spojrzeć nie tylko z perspektywy…</>,
      link:
        '/strefa-rodzica/czy-dawanie-kieszonkowego-sie-oplaca/aleksander-naganowski',
    },
  ]

  const expertCards = () => {
    return experts.map((expert, idx) => {
      return (
        <PaybackItem x={12} sm={6} xl={4} key={idx}>
          <PaybackItemLinkWrapper to={expert.link} title="Czytaj całość">
            <PaybackItemImageWrapper className="image">
              <Img
                fluid={data[`expertImage${idx + 1}`].childImageSharp.fluid}
                className="expertImage"
                alt={expert.text}
              />
            </PaybackItemImageWrapper>

            <PaybackItemDescWrapper>
              <p className="name">{expert.name}</p>
              <p className="desc">{expert.text}</p>
            </PaybackItemDescWrapper>
            <PaybackItemLink as="span">Czytaj całość</PaybackItemLink>
          </PaybackItemLinkWrapper>
        </PaybackItem>
      )
    })
  }

  return (
    <PaybackWrapper>
      <PaybackContainer>
        <Row>
          <PaybackTitleWrapper xs={12}>
            <PaybackTitle color="#91BD24">
              <span>
                Czy{'\u00A0'}dawanie kieszonkowego się{'\u00A0'}opłaca?
              </span>
            </PaybackTitle>
          </PaybackTitleWrapper>
        </Row>
        <PaybackRowWrapper>
          <PaybackItemFirst x={12} sm={6} xl={4}>
            <p>
              <strong>
                Posłuchaj głosu ekspertów, którzy wyjaśnią, dlaczego warto dawać
                dzieciom kieszonkowe.
              </strong>
              <br />W oparciu o naukowe przesłanki pomogą Ci podjąć racjonalne
              decyzje dotyczące finansów Twojego dziecka.
            </p>
          </PaybackItemFirst>
          {expertCards()}
        </PaybackRowWrapper>
      </PaybackContainer>
    </PaybackWrapper>
  )
}

export default Payback
