import React from 'react'
import styled from 'styled-components'
import { Container } from 'reactstrap'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import IntroHeader from '../../components/teachers/intro-header'
import IntroPocketMoney from '../../components/parent/intro-pocket-money'
import Partners from '../../components/teachers/partners'
import Patrons from '../../components/teachers/patrons'
import Chapter from '../../components/parent/chapter'
import PocketMoneyDetermine from '../../components/parent/pocket-money-determine'
import Payback from '../../components/parent/payback'
import Management from '../../components/parent/management'
import PocketMoneyBenefit from '../../components/parent/pocket-money-benefit'
/*import Support from '../../components/parent/support'
import SupportVideo from '../../components/parent/support-video'*/
import TopScroll from '../../components/parent/top-scroll'
import Back from '../../components/elements/back'
import LinksAnchors from '../../components/elements/links-anchors'

const IndexPageWrapper = styled.div`
  overflow: hidden;
`

const IndexPageInnerWrapper = styled.div``

const IndexPageIntroWrapper = styled.div`
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50%;
  }
  &:before {
    background: rgba(184, 225, 210, 1);
    background: linear-gradient(
      rgba(184, 225, 210, 0.9) 0%,
      rgba(255, 255, 255, 0.8) 70%
    );
    z-index: -2;
  }
  &:after {
    background: rgb(244, 242, 247);
    background: linear-gradient(
      50deg,
      rgba(244, 242, 247, 1) 0%,
      rgba(255, 255, 255, 0.35) 60%
    );
    z-index: -1;
  }
`

const IndexPageLinksAnchors = styled(LinksAnchors)``

const IndexPageTopScroll = styled(TopScroll)`
  @media (min-width: 768px) {
    display: none;
  }
`

const IndexPageIntroHeader = styled(IntroHeader)`
  @media (min-width: 992px) {
    padding-top: 15px;
  }
  .title {
    @media (max-width: 575px) {
      /* font-size: 20px; */
    }
    span {
      @media (max-width: 575px) {
        /* font-size: 14px; */
      }
    }
  }
  .introHeaderTitleWrapper {
    @media (max-width: 1199px) {
      flex: 0 0 60%;
      max-width: 60%;
    }
    @media (max-width: 575px) {
      flex: 0 0 60%;
      max-width: 60%;
    }
  }
  .introHeaderLinksWrapper {
    @media (max-width: 1199px) {
      flex: 0 0 40%;
      max-width: 40%;
    }
    @media (max-width: 575px) {
      flex: 0 0 40%;
      max-width: 40%;
    }
    .introHeaderLinksInnerWrapper {
      @media (max-width: 1199px) {
        text-align: right;
        padding-bottom: 30px;
        margin-right: -20px;
      }
      @media (max-width: 575px) {
        padding-top: 0;
      }
      .introHeaderButtonLink {
        padding-left: 0;
        @media (max-width: 575px) {
          margin-top: 0;
        }
      }
    }
  }
`

const IndexPageBack = styled(Back)`
  @media (min-width: 992px) {
    display: flex;
  }
  @media (max-width: 575px) {
    display: none;
  }
`

const IndexPage = ({ location }) => {
  return (
    <>
      <IndexPageWrapper>
        <IndexPageInnerWrapper>
          <SEO title="Misja Kieszonkowe - Strefa rodzica" />
          <Layout location={location}>
            <IndexPageIntroWrapper id="top">
              <div>
                <Container>
                  <IndexPageBack link="/strefa-rodzica" name="Strefa rodzica" />
                </Container>
              </div>
              <IndexPageIntroHeader
                title={
                  <>
                    Misja Kieszonkowe
                    <span>2022</span>
                  </>
                }
                links={[
                  {
                    link: '/strefa-rodzica/baza-wiedzy',
                    name: 'Baza wiedzy',
                  },
                ]}
              />
              <IntroPocketMoney />
            </IndexPageIntroWrapper>
            <IndexPageLinksAnchors
              list={[
                {
                  id: 'chapter',
                  name: 'Kapituła',
                  offset: -150,
                },
                {
                  id: 'pocket2022',
                  name: 'Kieszonkowe 2022',
                  offset: -100,
                },
                {
                  id: 'trivia',
                  name: 'Ciekawostki',
                  offset: -100,
                },
                /*                {
                  id: 'influencer',
                  name: 'Influencerzy',
                  offset: -100,
                },
*/
                {
                  id: 'partners',
                  name: 'Partnerzy',
                  offset: -100,
                },
              ]}
              settings={{
                mobileDropdowns: true,
              }}
            />
            <Chapter />
            <PocketMoneyDetermine />
            <Payback />
            <Management />
            <PocketMoneyBenefit />
            {/*            <Support />
            <SupportVideo />
*/}
            <Partners />
            {/*<Patrons />*/}
            <IndexPageTopScroll />
          </Layout>
        </IndexPageInnerWrapper>
      </IndexPageWrapper>
    </>
  )
}

export default IndexPage
