import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { TitleWithLine, expertBlockQuoteMixin } from '../elements'

import arrowGreenIcon from '../../images/arrow_select_green.svg'

const ChapterWrapper = styled.div`
  padding-bottom: 80px;
  @media (max-width: 575px) {
    padding-bottom: 30px;
  }
`

const ChapterContainer = styled(Container)``

const ChapterTitle = styled(TitleWithLine)``

const ChapterRowWrapper = styled(Row)`
  justify-content: center;
  padding-bottom: 50px;
  @media (min-width: 992px) {
    padding-left: 8%;
    padding-right: 8%;
  }
  @media (max-width: 575px) {
    padding-left: 10px;
    padding-right: 0;
  }
`

const ChapterDescWrapper = styled.div`
  text-align: center;
  @media (max-width: 1399px) {
    font-size: 16px;
  }
  @media (min-width: 1200px) {
    padding-left: 25%;
    padding-right: 25%;
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    padding-left: 15%;
    padding-right: 15%;
  }
`

const ChapterSliderWrapper = styled(Col)`
  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const ChapterSlider = styled(Slider)`
  margin-top: 50px;
  .slick-list {
    @media (max-width: 575px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .slick-slide {
    @media (max-width: 575px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    &.slick-center {
      &.slick-current {
        .name {
        }
      }
    }
  }
  .slick-dots {
    bottom: -40px;
    z-index: 10;
    li {
      width: 25px;
      margin-left: 0;
      margin-right: 0;
      &.slick-active {
        button {
          &:before {
            background-color: ${(props) => props.theme.colorGreenLight};
          }
        }
      }
      button {
        width: 25px;
        &:before {
          content: '';
          width: 15px;
          height: 15px;
          border: 1px solid ${(props) => props.theme.colorGreenLight};
          border-radius: 50%;
        }
      }
    }
  }
`

const ChapterSliderItem = styled.div`
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  outline: none;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (max-width: 767px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const ChapterSliderItemLinkWrapper = styled.a`
  display: block;
  text-decoration: none !important;
  transition: all 0.2s ease;
  &:hover {
    .name {
      &:after {
        right: -5px;
      }
    }
  }
`

const ChapterSliderItemImageWrapper = styled.div``

const ChapterSliderItemDescWrapper = styled.div`
  line-height: 1;
  margin-top: 25px;
  p {
    margin-bottom: 5px;
    &.name {
      position: relative;
      font-size: 18px;
      color: ${(props) => props.theme.colorGrayMiddle};
      padding-right: 15px;
      @media (min-width: 1200px) and (max-width: 1399px) {
        font-size: 16px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 14px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 18px;
      }
      @media (max-width: 767px) {
        /* font-size: 19px; */
      }
      @media (max-width: 575px) {
        font-size: 18px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 10px;
        height: 10px;
        background-image: url(${arrowGreenIcon});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translateY(-50%) rotate(-90deg);
      }
      span {
        display: block;
        font-size: 30px;
        @media (min-width: 1200px) and (max-width: 1399px) {
          font-size: 26px;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 22px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          font-size: 24px;
        }
        @media (max-width: 767px) {
          font-size: 22px;
        }
        @media (max-width: 575px) {
          display: none;
          font-size: 20px;
        }
      }
    }
  }
`

const ExpertsBlockQuote = styled.div`
  ${expertBlockQuoteMixin}
  background-color: rgba(211, 234, 224,0.95);
`

const Chapter = (props) => {
  const data = useStaticQuery(graphql`
    query {
      personImage1: file(
        relativePath: { eq: "parent/chapter/natalia-tur.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      personImage2: file(
        relativePath: { eq: "parent/chapter/maciej-samcik.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      personImage3: file(
        relativePath: { eq: "parent/chapter/tatiana-mindewicz-puacz.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      personImage4: file(
        relativePath: { eq: "parent/chapter/monika-sajkowska.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      personImage5: file(
        relativePath: { eq: "parent/chapter/aleksander-naganowski.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 496, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      personImage6: file(
        relativePath: { eq: "parent/chapter/grzegorz-markowski.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 497, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const [activeSlide, setActiveSlide] = useState(0)
  const [activeQuote, setActiveQuote] = useState(false)

  const sliderSettings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 15000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerPadding: '70px',
        },
      },
    ],
  }

  const experts = [
    {
      name: 'Natalia <span>Tur</span>',
      link: '/',
      text: (
        <>
          Natalia Tur - socjolożka rodziny, mediatorka rodzinna, w{'\u00A0'}
          trakcie szkolenia psychoterapeutycznego. Od{'\u00A0'}2013
          {'\u00A0'}r. prowadzi socjologiczno-psychologicznego bloga, a
          {'\u00A0'}od{'\u00A0'}2018 jest również założycielką kanału na
          YouTube, gdzie funkcjonuje pod pseudonimem „Nishka”. Opowiada tam o
          {'\u00A0'}życiu w{'\u00A0'}rodzinie i{'\u00A0'}społeczeństwie oraz
          dzieli się ciekawostkami z{'\u00A0'}psychologii społecznej. Swoją
          wiedzę przekazuje z{'\u00A0'}humorem i{'\u00A0'}popiera ją{'\u00A0'}
          przykładami z{'\u00A0'}życia. Prywatnie mama 21-{'\u00A0'}i{'\u00A0'}
          16-letnich córek oraz 5-letniego syna.
        </>
      ),
    },
    {
      name: 'Maciej <span>Samcik</span>',
      link: '/',
      text: (
        <>
          Maciej Samcik - bloger, publicysta, analityk zjawisk finansowych.
          Właściciel serwisu „Subiektywnie o finansach”. Od 2009{'\u00A0'}r. do
          2017{'\u00A0'}r. prowadził blog o{'\u00A0'}tej samej nazwie. W latach
          1997-2018 dziennikarz finansowy „Gazety Wyborczej” i{'\u00A0'}twórca
          Ekipy Samcika (grupy dziennikarzy prowadzących działalność
          interwencyjną). W{'\u00A0'}2005{'\u00A0'}r. otrzymał nagrodę „Grand
          Press” w{'\u00A0'}kategorii „dziennikarstwo specjalistyczne”, a
          {'\u00A0'}w{'\u00A0'}2014{'\u00A0'}r. - "Grand Press Economy". Autor
          czterech książek poświęconych pieniądzom i{'\u00A0'}problemom
          konsumentów. Prywatnie ojciec dwójki dzieci.
        </>
      ),
    },
    {
      name: 'Tatiana <span>Mindewicz-Puacz</span>',
      link: '/',
      text: (
        <>
          Tatiana Mindewicz-Puacz - psychoterapeutka, specjalistka od
          psychologii zmiany, coach ICC, przedsiębiorca, ekspert do{'\u00A0'}
          spraw komunikacji (budowanie relacji) i{'\u00A0'}kampanii społecznych,
          publicystka. Zawodowo zajmuje się wspieraniem ludzi w{'\u00A0'}
          odkrywaniu i{'\u00A0'}uruchamianiu ich potencjału oraz pomaganiem im w
          {'\u00A0'}osiąganiu celów. Od lat tworzy i{'\u00A0'}wdraża projekty
          PR-owe i{'\u00A0'}
          CSR-owe. Realizowała ogólnopolskie akcje społeczne: „Wyloguj się do
          życia”, „DDA - Jak skutecznie pomagać”, „AlcoSfery - picie w
          biznesklasie” czy „Jak działa ZDROWY człowiek”. Prywatnie jest mamą
          dwóch synów - Bartosza i{'\u00A0'}Jakuba.
        </>
      ),
    },
    {
      name: 'Monika <span>Sajkowska</span>',
      link: '/',
      text: (
        <>
          Monika Sajkowska – dr socjologii, prezeska zarządu Fundacji Dajemy
          Dzieciom Siłę. Przez 16{'\u00A0'}lat wykładała {'\u00A0'}Instytucie
          Nauk Społecznych UW. Założyła kwartalnik naukowy „Dziecko Krzywdzone.
          Teoria, Badania, Praktyka” i{'\u00A0'}do{'\u00A0'}2017{'\u00A0'}r.
          była jego redaktorką naczelną. Członkini rad społecznych Rzecznika
          Praw Obywatelskich i{'\u00A0'}Rzecznika Praw Dziecka poprzednich
          kadencji. Bada problem krzywdzenia dzieci w{'\u00A0'}Polsce. Zdobyła
          nagrodę Polskiej Rady Biznesu w{'\u00A0'}kategorii działalność
          społeczna oraz została Bizneswoman roku 2021 za całokształt działań.
          Prywatnie mama dwóch dorosłych synów.
        </>
      ),
    },
    {
      name: 'Aleksander <span>Naganowski</span>',
      link: '/',
      text: (
        <>
          Aleksander Naganowski - dyrektor ds.{'\u00A0'}rozwoju rozwiązań
          cyfrowych w{'\u00A0'}polskim oddziale Mastercard Europe, menedżer i
          {'\u00A0'}inżynier. Wdraża nowoczesne technologie takie jak biometria,
          tokenizcja i{'\u00A0'}uczenie maszynowe. Chętnie dzieli się wiedzą i
          {'\u00A0'}doświadczeniem. Był zaangażowany m.in. w{'\u00A0'}projekt
          Artura Kurasińskiego „Róża, a{'\u00A0'}co{'\u00A0'}chcesz wiedzieć?”,
          czyli komiks dla dzieci, którego celem jest wyrównanie szans w
          {'\u00A0'}dostępie do wiedzy dla chłopców i{'\u00A0'}dziewczynek oraz
          edukacja w{'\u00A0'}obszarze nowych technologii. Prywatnie tata trójki
          dzieci.
        </>
      ),
    },
    {
      name: 'Grzegorz <span>Markowski</span>',
      link: '/',
      text: (
        <>
          Grzegorz Markowski - partner w CPC Brand Consultants, gdzie prowadzi
          projekty z{'\u00A0'}zakresu strategii marki m.in. dla Play, Grupy
          Żywiec, Škoda, McDonald’s. Specjalista w{'\u00A0'}dziedzinie
          pozycjonowania marki i{'\u00A0'}insightu konsumenckiego. Zdobył kilka
          nagród Effie, w{'\u00A0'}tym Grand Prix dla marki Play. Współtworzy
          inicjatywy społeczne: #solidarityintruth, Kultura Niepodległa,
          #WolnaSzkoła i{'\u00A0'}trzy kampanie profrekwencyjne dla Fundacji
          Batorego. Można go zobaczyć w{'\u00A0'}programie „Szkło kontaktowe” i
          {'\u00A0'}usłyszeć w{'\u00A0'}porankach Radia Nowy Świat. Prywatnie
          spełnia się w roli ojca Anieli, Jasia i{'\u00A0'}Karoliny.
        </>
      ),
    },
  ]

  const changeActiveSlide = (number) => {
    setActiveSlide(number)
    setActiveQuote(true)
  }
  const closeActiveQuote = () => {
    setActiveQuote(false)
  }

  const personCards = (activeSlide) => {
    return experts.map((expert, idx) => {
      return (
        <ChapterSliderItem key={idx} onClick={() => changeActiveSlide(idx)}>
          {/*<ChapterSliderItemLinkWrapper
            as={Link}
            to="/"
            title={expert.name.replace(/<\/?[a-zA-Z]+>/gi, '')}
          >*/}
          <ChapterSliderItemLinkWrapper>
            <ChapterSliderItemImageWrapper
              index={idx}
              current={activeSlide}
              className="image"
            >
              <Img
                fluid={data[`personImage${idx + 1}`].childImageSharp.fluid}
                className="expertImage"
                alt={expert.name.replace(/<\/?[a-zA-Z]+>/gi, '')}
              />
            </ChapterSliderItemImageWrapper>

            <ChapterSliderItemDescWrapper index={idx} current={activeSlide}>
              <p
                className="name"
                dangerouslySetInnerHTML={{ __html: expert.name }}
              />
            </ChapterSliderItemDescWrapper>
          </ChapterSliderItemLinkWrapper>
        </ChapterSliderItem>
      )
    })
  }

  return (
    <ChapterWrapper>
      <ChapterContainer>
        <Row>
          <Col>
            <ChapterTitle color="#91BD24" id="chapter">
              <span>KAPITUŁA</span>
            </ChapterTitle>
            <ChapterDescWrapper>
              <p>
                <strong>Wartościowe wskazówki ekspertów</strong>
              </p>
              <p>
                Posłuchaj głosu ekspertów, którzy wyjaśnią i{'\u00A0'}pokażą,
                jak ważną rolę pełni kieszonkowe w{'\u00A0'}edukacji finansowej
                dzieci. W{'\u00A0'}oparciu o{'\u00A0'}naukowe przesłanki pomogą
                Ci{'\u00A0'}podjąć racjonalne decyzje dotyczące finansów Twojego
                dziecka.
              </p>
            </ChapterDescWrapper>
          </Col>
        </Row>
        <ChapterRowWrapper>
          <ChapterSliderWrapper xs={12}>
            <ChapterSlider {...sliderSettings}>
              {personCards(activeSlide)}
            </ChapterSlider>
            {activeQuote && (
              <ExpertsBlockQuote
                open={activeQuote}
                active={activeSlide}
                qty={experts.length}
              >
                <span className="close" onClick={() => closeActiveQuote()} />
                {experts[activeSlide].text}
              </ExpertsBlockQuote>
            )}
          </ChapterSliderWrapper>
        </ChapterRowWrapper>
      </ChapterContainer>
    </ChapterWrapper>
  )
}

export default Chapter
