import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { withPrefix } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { PocketMoneyDetermineTitle } from './pocket-money-determine'
import { ButtonLink } from '../elements'

import downloadIcon from '../../images/download_green_icon.svg'

const ManagementWrapper = styled.div`
  background-color: rgba(145, 189, 36, 0.15);
  padding-top: 40px;
  padding-bottom: 40px;
`

const ManagementContainer = styled(Container)`
  @media (min-width: 768px) {
    position: relative !important;
  }
`

const ManagementRowWrapper = styled(Row)`
  @media (min-width: 768px) {
    position: static;
  }
`

const ManagementTitle = styled(PocketMoneyDetermineTitle)`
  text-transform: capitalize;
  & > span {
    text-transform: uppercase;
    &:after {
      right: 52%;
    }
  }
`

const ManagementDescWrapper = styled(Col)`
  position: static;
  display: flex;
  align-items: center;
  @media (min-width: 1200px) {
    min-height: 480px;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    min-height: 390px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    min-height: 330px;
  }
  .wrapper {
    width: 100%;
    .buttonWrapper {
      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }
`

const ManagementDesc = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;
  padding-top: 10px;
  margin-bottom: 0;
  @media (min-width: 1200px) and (max-width: 1399px) {
    font-size: 22px;
  }
  @media (min-width: 768px) {
    padding-right: 8%;
  }
  @media (max-width: 767px) {
    margin-top: 30px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }
`

const ManagementImageWrapper = styled(Col)``

const ManagementImageInnerWrapper = styled.div`
  @media (min-width: 768px) {
    position: absolute;
    top: 0;
    left: 50%;
    right: 15px;
    bottom: 0;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    top: 50%;
    transform: translateY(-75%);
  }
  .imagerapper {
    width: 100%;
  }
`

const ManagementDownloadLink = styled(ButtonLink)`
  margin-top: 35px;
  margin-right: 40px;
  margin-bottom: 0;
  @media (max-width: 575px) {
    font-size: 18px;
    padding-left: 40px;
    padding-right: 40px;
    margin-right: 15px;
  }
  &:hover {
    opacity: 0.9;
  }
  &:last-of-type {
    margin-right: 0;
  }
  &:before {
    content: none;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 28px;
    display: block;
    width: 20px;
    height: 15px;
    background-image: url(${downloadIcon});
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;
    transform: translateY(-50%);
    @media (max-width: 575px) {
      left: 15px;
      width: 15px;
    }
  }
`

const Management = (props) => {
  const data = useStaticQuery(graphql`
    query {
      managmentImage: file(relativePath: { eq: "parent/managment.png" }) {
        childImageSharp {
          fluid(maxWidth: 680) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ManagementWrapper>
      <ManagementContainer>
        <ManagementRowWrapper>
          <ManagementDescWrapper xs={12} md={6}>
            <div className="wrapper">
              <ManagementTitle color="#91BD24">
                Kieszonkowy
                <span>
                  <span>poradnik rodzica</span>
                </span>
              </ManagementTitle>
              <ManagementImageInnerWrapper>
                <div className="imagerapper">
                  <Img
                    fluid={data.managmentImage.childImageSharp.fluid}
                    className="Kieszonkowy poradnik rodzica"
                  />
                </div>
              </ManagementImageInnerWrapper>
              <ManagementDesc>
                Masz wątpliwość, jak{'\u00A0'}rozmawiać z{'\u00A0'}dziećmi o
                {'\u00A0'}kieszonkowym?
              </ManagementDesc>
              <div className="buttonWrapper">
                <ManagementDownloadLink
                  href={withPrefix(
                    '/docs/BNP_Misja%20Kieszonkowe_Definicja%20i%20zasady%20kieszonkowego-Poradnik.pdf'
                  )}
                  target="_blank"
                  title="Pobierz"
                >
                  Pobierz
                </ManagementDownloadLink>
              </div>
            </div>
          </ManagementDescWrapper>
          <ManagementImageWrapper x={12} md={6} />
        </ManagementRowWrapper>
      </ManagementContainer>
    </ManagementWrapper>
  )
}

export default Management
