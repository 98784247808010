import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { TitleWithLine } from '../elements'

import PartnerMCImage from '../../images/teachers/partner_mc.svg'

const PartnersWrapper = styled.div`
  padding-top: 40px;
`

const PartnersTopWrapper = styled.div`
  padding-bottom: 10px;
`

const PartnersTopContainer = styled(Container)``

const PartnersTitle = styled(TitleWithLine)``

const PartnersBottomWrapper = styled.div`
  background-color: #f7f7f7;
  color: ${(props) => props.theme.colorGray};
`

const PartnersBottomContainer = styled(Container)`
  padding-top: 40px;
  padding-bottom: 80px;
  @media (min-width: 1200px) {
    padding-left: 8%;
    padding-right: 8%;
  }
  @media (max-width: 575px) {
    padding-bottom: 40px;
  }
`

const PartnersBottomRowWrapper = styled(Row)`
  justify-content: center;
`

const PartnersCardItem = styled(Col)`
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-style: italic;
  @media (min-width: 1200px) {
    flex: 0 0 30%;
    max-width: 30%;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
  @media (max-width: 1199px) {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 40px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
    padding-bottom: 20px;
  }
`

const PartnersCardImageWrapper = styled.div`
  overflow: hidden;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 50%;

  img {
    height: auto;
    max-width: 100%;
  }
`

const PartnersCardTitle = styled.p`
  text-align: center;
  margin-top: 15px;
`

const Partners = (props) => {
  return (
    <PartnersWrapper id="partners">
      <PartnersTopWrapper>
        <PartnersTopContainer>
          <Row>
            <Col xs={12}>
              <PartnersTitle color="#91BD24">
                <span>Partnerzy konkursu</span>
              </PartnersTitle>
            </Col>
          </Row>
        </PartnersTopContainer>
      </PartnersTopWrapper>
      <PartnersBottomWrapper>
        <PartnersBottomContainer>
          <PartnersBottomRowWrapper>
            <PartnersCardItem xs={4} xl={3}>
              <PartnersCardImageWrapper>
                <img src={PartnerMCImage} alt={`Mastercard`} />
              </PartnersCardImageWrapper>
              <PartnersCardTitle>{`Mastercard`}</PartnersCardTitle>
            </PartnersCardItem>
          </PartnersBottomRowWrapper>
        </PartnersBottomContainer>
      </PartnersBottomWrapper>
    </PartnersWrapper>
  )
}

export default Partners
