import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link as LinkScroll } from 'react-scroll'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { ButtonLink } from '../elements'

const IntroPocketMoneyWrapper = styled.div``

const IntroPocketMoneyInnerWrapper = styled.div`
  position: relative;
`

const IntroPocketMoneyImageWrapper = styled.div``

const IntroPocketMoneyContentWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 6;
  @media (min-width: 992px) {
    position: absolute;
    color: ${(props) => props.theme.colorWhite};
    padding-left: 55%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 45%;
  }
  @media (max-width: 575px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
    @media (min-width: 1200px) and (max-width: 1399px) {
      font-size: 14px;
      margin-bottom: 20px;
    }
    @media (max-width: 1199px) {
      font-size: 15px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 14px;
      margin-bottom: 20px;
    }
    @media (min-width: 992px) {
      text-align: right;
    }
  }
`

const IntroPocketMoneyContentInnerWrapper = styled.div``

const IntroPocketMoneyContenttitle = styled.h2`
  font-size: 42px;
  font-weight: 400;
  @media (min-width: 1200px) and (max-width: 1399px) {
    font-size: 38px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 28px;
  }
  @media (max-width: 991px) {
    color: ${(props) => props.theme.colorWhite};
    position: relative;
    padding-bottom: 50px;
    z-index: 20;
  }
  @media (min-width: 992px) {
    text-align: right;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 28px;
    margin-top: -110px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px;
    margin-top: -100px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
    padding-bottom: 30px;
    margin-top: -50px;
  }
  span {
    display: block;
    font-size: 76px;
    font-weight: 700;
    @media (min-width: 1200px) and (max-width: 1399px) {
      font-size: 68px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 54px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 60px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 50px;
    }
    @media (max-width: 575px) {
      font-size: 24px;
    }
    @media (max-width: 340px) {
    }
  }
`

const IntroPocketMoneyDescLinkWrapper = styled.div`
  text-align: center;
  @media (min-width: 992px) {
    text-align: right;
  }
`

export const IntroPocketMoneyDescLink = styled(ButtonLink)`
  @media (max-width: 575px) {
    width: 100%;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
  }
`

const IntroPocketMoney = (props) => {
  const data = useStaticQuery(graphql`
    query {
      introPocketMoney: file(
        relativePath: { eq: "parent/intro_pocket_money.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1364, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <IntroPocketMoneyWrapper>
      <Container>
        <Row>
          <Col xs={12}>
            <IntroPocketMoneyInnerWrapper>
              <IntroPocketMoneyImageWrapper>
                <Img
                  fluid={data.introPocketMoney.childImageSharp.fluid}
                  className="Kieszonkowe to inwestycja?"
                />
              </IntroPocketMoneyImageWrapper>
              <IntroPocketMoneyContentWrapper>
                <IntroPocketMoneyContentInnerWrapper>
                  <IntroPocketMoneyContenttitle>
                    Kieszonkowe to{'\u00A0'}inwestycja? <span>Oczywiście!</span>
                  </IntroPocketMoneyContenttitle>
                  <p>
                    Nie tylko w{'\u00A0'}finansowy, ale też w{'\u00A0'}
                    emocjonalny rozwój Twojego dziecka.
                    <br />
                    Uważasz, że edukacja finansowa dzieci jest nie tylko ważna,
                    ale i{'\u00A0'}potrzebna? Zależy Ci{'\u00A0'}na
                    przygotowaniu Twojego dziecka do{'\u00A0'}dorosłości i
                    {'\u00A0'}wyrobieniu w{'\u00A0'}nim dobrych nawyków, które
                    przełożą się na{'\u00A0'}odpowiedzialne gospodarowanie
                    pieniędzmi w{'\u00A0'}przyszłości? Potrzebujesz wsparcia w
                    {'\u00A0'}tym temacie?
                  </p>
                  <p>
                    Pomogą Ci{'\u00A0'}w Tym nasi uznani eksperci i specjaliści
                    z zakresu ekonomii, socjologii, pedagogiki i psychologii.
                    <br />
                    <strong>
                      Chcesz wiedzieć, jakie kieszonkowe przekazują rodzice
                      dzieciom w Polsce?
                    </strong>
                  </p>
                  <IntroPocketMoneyDescLinkWrapper>
                    <IntroPocketMoneyDescLink
                      as={LinkScroll}
                      to="pocket2022"
                      offset={-100}
                      smooth
                      title="Sprawdź wysokość kieszonkowego"
                    >
                      Sprawdź wysokość kieszonkowego
                    </IntroPocketMoneyDescLink>
                  </IntroPocketMoneyDescLinkWrapper>
                </IntroPocketMoneyContentInnerWrapper>
              </IntroPocketMoneyContentWrapper>
            </IntroPocketMoneyInnerWrapper>
          </Col>
        </Row>
      </Container>
    </IntroPocketMoneyWrapper>
  )
}

export default IntroPocketMoney
