import React, { useState } from 'react'
import { Link } from 'react-scroll'
import styled from 'styled-components'
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

export const LinksAnchorsWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  @media (max-width: 575px) {
    padding-top: 40px;
    padding-bottom: 40px;
    ${(props) =>
      !props.show &&
      `
      display: none;
    `}
  }
`

export const LinksAnchorsContainer = styled(Container)`
  position: relative;
  &:before {
    @media (max-width: 575px) {
      content: '';
      top: 0;
      left: 15px;
      right: 15px;
      display: block;
      height: 1px;
      background-color: rgb(215, 215, 215);
      z-index: 10;
    }
  }
`

export const LinksAnchorsListWrapper = styled(Col)``

export const LinksAnchorsDropdownsWrapper = styled(Col)`
  @media (max-width: 575px) {
    padding-top: 40px;
  }
`

export const LinksAnchorsList = styled.ul`
  list-style-type: none;
  text-align: center;
  padding-left: 0;
  margin-bottom: 0;
  @media (max-width: 575px) {
    display: none;
  }
  li {
    position: relative;
    display: inline-block;
    line-height: 1;
    &:last-of-type {
      &:before {
        content: none;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 15px;
      right: 0;
      bottom: 0;
      display: block;
      width: 1px;
      background-color: ${(props) => props.theme.colorBlack};
    }
  }
`

export const LinksAnchorsListItem = styled(Link)`
  color: ${(props) => props.theme.colorBlack};
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 24px;
  font-weight: 700;
  padding-left: 30px;
  padding-right: 30px;
  transition: all 0.2s ease;
  cursor: pointer;
  @media (min-width: 1400px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: 575px) {
    font-family: ${(props) => props.theme.fontBNPPSans};
    font-size: 16px;
    font-weight: 400;
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorBlack};
    text-decoration: none;
  }
`

export const LinksAnchorsDropdown = styled(Dropdown)`
  display: none;
  width: 100%;
  background-color: transparent;
  @media (max-width: 575px) {
    display: block;
  }
  .dropdown-toggle {
    position: relative;
    width: 100%;
    background-color: transparent;
    font-family: ${(props) => props.theme.fontBNPPSans}!important;
    color: ${(props) => props.theme.colorBlack};
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    border-color: rgb(215, 215, 215) !important;
    border-style: solid;
    border-radius: 5px;
    &:hover,
    &:focus {
      background-color: transparent !important;
      color: ${(props) => props.theme.colorBlack} !important;
    }
    &:after {
      position: absolute;
      top: 50%;
      right: 20px;
      border-top: 0.3em solid ${(props) => props.theme.colorBlack};
      transform: translateY(-50%);
    }
  }
  .dropdown-item {
    text-align: center;
  }
  .dropdown-menu {
    width: 100%;
    border-color: rgb(215, 215, 215) !important;
    border-style: solid;
  }
`

// For example structure

const LinksAnchors = (props) => {
  const { list, settings, className } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  if (!list || list.length === 0) {
    return null
  }

  return (
    <LinksAnchorsWrapper
      show={
        typeof settings?.mobileDropdowns !== 'undefined'
          ? settings?.mobileDropdowns
          : true
      }
      className={className}
    >
      <LinksAnchorsContainer>
        <Row>
          <LinksAnchorsListWrapper xs={12}>
            <LinksAnchorsList>
              {list.map((listItem, idx) => {
                return (
                  <li key={idx}>
                    <LinksAnchorsListItem
                      to={listItem.id}
                      smooth
                      offset={listItem.offset}
                    >
                      {listItem.name}
                    </LinksAnchorsListItem>
                  </li>
                )
              })}
            </LinksAnchorsList>
          </LinksAnchorsListWrapper>
          {settings?.mobileDropdowns && (
            <LinksAnchorsDropdownsWrapper xs={12}>
              <LinksAnchorsDropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>{list[0].name}</DropdownToggle>
                <DropdownMenu>
                  {list.slice(1, list.length).map((listItem, idx) => {
                    return (
                      <DropdownItem key={idx}>
                        <LinksAnchorsListItem
                          to={listItem.id}
                          onClick={() => toggle()}
                          smooth
                          offset={listItem.offset}
                        >
                          {listItem.name}
                        </LinksAnchorsListItem>
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </LinksAnchorsDropdown>
            </LinksAnchorsDropdownsWrapper>
          )}
        </Row>
      </LinksAnchorsContainer>
    </LinksAnchorsWrapper>
  )
}

export default LinksAnchors
